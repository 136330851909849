<template>
  <ServiceComboFormPart
    :service-types="serviceTypes"
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  solarPanelsPurchaseConsumerFR,
  solarPanelsPurchaseCorporateFR,
  solarPanelsPurchaseSolarCollectorsConsumerFR,
  solarPanelsPurchaseSolarCollectorsCorporateFR,
} from 'chimera/solarPanels/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey,
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable(
          'Maison résidentielle',
          'Maison résidentielle',
          consumerKey,
        ),
        new Selectable(
          'Immeuble commercial',
          'Immeuble commercial',
          corporateKey,
        ),
        new Selectable(
          'Entreprise agricole',
          'Entreprise agricole',
          corporateKey,
        ),
        new Selectable(
          "Grands projets d'entreprise",
          "Grands projets d'entreprise",
          corporateKey,
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Panneaux solaires',
          'Panneaux solaires',
          'Panneaux solaires',
          {
            consumer: solarPanelsPurchaseConsumerFR,
            corporate: solarPanelsPurchaseCorporateFR,
          },
        ),
        new Selectable(
          'Chaudière solaire / Capteurs solaires',
          'Chaudière solaire / Capteurs solaires',
          'Chaudière solaire / Capteurs solaires',
          {
            consumer: solarPanelsPurchaseSolarCollectorsConsumerFR,
            corporate: solarPanelsPurchaseSolarCollectorsCorporateFR,
          },
        ),
      ],
    }
  },
}
</script>
